.hover-container {
  .show-on-hover {
    display: none;
  }

  &:hover {
    .show-on-hover {
      display: initial;
    }
  }
}
