/*
  Styling for content pages
  Including: about, home, blog, /for/__, legal, contact, portals, wiki
*/
.content-page {
  word-break: break-word;

  /*
    Links and Buttons
  */
  &:not(.page-style) {
    a {
      color: @link-color-alt;
      &:hover {
        color: @link-hover-color-alt;
      }
    }
    // correct color property set on <a> above:
    .reset-btns;
    .alert {
      .alert;
    }
    .alert-info {
      .btn-info {
        .btn-alert-info;
      }
    }
  }

  // correct color property set on <a> above:
  .reset-btns;
  .alert {
    .alert;
  }
  .alert-info {
    .btn-info {
      .btn-alert-info;
    }
  }

  hr {
    border-color: @hr-border-alt;
  }

  .quote-by {
    min-width: 80px;
    overflow: hidden;
  }

  .page-header {
    margin-top: 0;
  }

  /*
    section
  */
  section {
    padding: @line-height-computed (@grid-gutter-width / 2);

    &.color-block {
      &.green-dark {
        background-color: @ol-dark-green;
      }
      &.green {
        background-color: @ol-green;

        .no-card * {
          .btn-primary,
          .btn-success {
            // only correct button colors when green on green
            .btn-primary-on-primary-bg;
          }
        }
      }
      &.blue-gray-dark {
        background-color: @ol-blue-gray-5;
      }
      &.blue-gray-light {
        background-color: @ol-blue-gray-1;
      }

      &.green,
      &.green-dark,
      &.blue-gray-dark {
        .no-card * {
          color: @white;
          a {
            text-decoration: underline;
          }
          .btn {
            text-decoration: none;
          }

          .form-control {
            color: @input-color;
          }
        }
      }
    }
    .section-row {
      margin: 0 auto;
      /* match .col-sm-10 */
      /* @grid-gutter-width  is used for margins */
      max-width: (@screen-sm * (10/12)) - @grid-gutter-width;
      @media (min-width: @screen-md-min) {
        max-width: (@screen-md * (10/12)) - @grid-gutter-width;
      }
      @media (min-width: @screen-lg-min) {
        max-width: (@screen-lg * (10/12)) - @grid-gutter-width;
      }
    }
  }
  .content-container > section:first-child {
    /* header */
    padding-bottom: 0;
    padding-top: 0;
  }
  .content-container > section:nth-child(2) {
    /* first content section */
    padding-top: 0;
  }
  section.no-top-padding {
    /* opt out of padding via the CMS */
    padding-top: 0;
  }
  .container-small {
    section .section-row {
      /* match col-sm-8 */
      /* @grid-gutter-width  is used for margins */
      max-width: (@screen-sm * (8/12)) - @grid-gutter-width;
      @media (min-width: @screen-md-min) {
        max-width: (@screen-md * (8/12)) - @grid-gutter-width;
      }
      @media (min-width: @screen-lg-min) {
        max-width: (@screen-lg * (8/12)) - @grid-gutter-width;
      }
    }
  }

  /*
    lists
  */

  .list-without-style {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
