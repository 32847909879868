.MathJax_Hover_Frame {
  border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  -khtml-border-radius: 0.25em;
  box-shadow: 0px 0px 15px #83a;
  -webkit-box-shadow: 0px 0px 15px #83a;
  -moz-box-shadow: 0px 0px 15px #83a;
  -khtml-box-shadow: 0px 0px 15px #83a;
  border: 1px solid #a6d !important;
  display: inline-block;
  position: absolute;
}
.MathJax_Menu_Button .MathJax_Hover_Arrow {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #aaa;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  font-family: 'Courier New', Courier;
  font-size: 9px;
  color: #f0f0f0;
}
.MathJax_Menu_Button .MathJax_Hover_Arrow span {
  display: block;
  background-color: #aaa;
  border: 1px solid;
  border-radius: 3px;
  line-height: 0;
  padding: 4px;
}
.MathJax_Hover_Arrow:hover {
  color: white !important;
  border: 2px solid #ccc !important;
}
.MathJax_Hover_Arrow:hover span {
  background-color: #ccc !important;
}
