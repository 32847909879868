.project-list-sidebar-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-list-sidebar {
  flex-grow: 1;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.project-list-sidebar-survey {
  position: relative;
  font-size: @font-size-small;
  background-color: @v2-dash-pane-bg;
  color: @v2-dash-pane-subdued-color;
  padding: @folders-menu-item-v-padding 20px @folders-menu-item-v-padding
    @folders-menu-item-h-padding;
  &::before {
    content: '';
    display: block;
    height: 15px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
    position: absolute;
    bottom: 100%;
    width: 100%;
    left: 0;
  }
}

.project-list-sidebar-survey-link {
  color: @v2-dash-pane-color;
  font-weight: bold;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: @v2-dash-pane-color;
  }
}

.project-list-sidebar-survey-dismiss {
  .btn-inline-link;
  position: absolute;
  top: @folders-menu-item-v-padding;
  right: @folders-menu-item-v-padding;
  font-size: @font-size-base;
  line-height: 1;
  color: @v2-dash-pane-color;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: @v2-dash-pane-color;
  }
}

.project-list-sidebar-v2-pane {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  background-color: @v2-dash-pane-bg;
  color: @v2-dash-pane-color;
  font-size: 13px;
  padding: @line-height-computed / 2;
}

.project-list-sidebar-v2-pane a {
  color: @v2-dash-pane-link-color;
  text-decoration: underline;
}

.project-list-sidebar-v2-pane a:hover {
  text-decoration: none;
}

.project-list-sidebar-v2-pane-toggle {
  color: @v2-dash-pane-toggle-color;
  padding: 0;
  line-height: 1;
  &:hover,
  &:active,
  &:focus {
    color: @v2-dash-pane-toggle-color;
  }
}
