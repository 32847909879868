.account-settings {
  .alert {
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0;
  }
}

#delete-account-modal {
  .alert {
    margin-top: 25px;
    margin-bottom: 4px;
  }
  .confirmation-checkbox-wrapper {
    padding-top: 8px;
    input {
      margin-right: 6px;
    }
  }
}

.affiliations-table {
  table-layout: fixed;
}
.affiliations-table-cell {
  padding: 0.5rem;
  overflow-wrap: break-word;
}
.affiliations-table-cell-tabbed {
  margin: @margin-sm 0 0 @margin-md;
  padding-left: @margin-sm;
  border-left: 2px solid @table-border-color;
}
.affiliations-table-row--highlighted {
  background-color: tint(@content-alt-bg-color, 6%);
}
.affiliations-table-email {
  width: 40%;
}
.affiliations-table-institution {
  width: 40%;
}
.affiliations-table-inline-actions {
  padding: 0 !important;
  text-align: right;
  word-wrap: break-word;
  button {
    margin: @table-cell-padding 0;
  }
}
.affiliations-table-inline-action {
  text-transform: capitalize;
}
.affiliations-table-inline-action-disabled-wrapper {
  display: inline-block;
}
.affiliations-table-highlighted-row {
  background-color: tint(@content-alt-bg-color, 6%);
}
.affiliations-table-error-row {
  background-color: @alert-danger-bg;
  color: @alert-danger-text;
  .btn {
    margin-top: @table-cell-padding;
    .button-variant(
      @btn-danger-color; darken(@btn-danger-bg, 8%) ; @btn-danger-border
    );
  }
  .small {
    color: @alert-danger-text;
  }
}
.affiliations-table-info-row {
  background-color: @alert-info-bg;
  color: @alert-info-text;
  .small {
    color: @alert-info-text;
  }
}
.affiliations-table-warning-row {
  background-color: @alert-warning-bg;
  color: @alert-warning-text;
  .small {
    color: @alert-warning-text;
  }
}
tbody > tr.affiliations-table-saml-row > td:not(.with-border) {
  border: 0;
}
tbody > tr.affiliations-table-info-row > td {
  border: 0;
}
tbody > tr.affiliations-table-warning-row > td {
  border: 0;
}
.affiliations-form-group {
  margin-top: @table-cell-padding;
  &:first-child {
    margin-top: 0;
  }
}
.affiliation-change-container,
.affiliation-change-actions {
  margin-top: @table-cell-padding;
}

.affiliations-table-label {
  padding-top: 4px;
}

.btn-link-accounts {
  margin-bottom: (@line-height-computed / 2) - @table-cell-padding;
}

.settings-widget-status-icon,
.dropbox-sync-icon {
  position: relative;
  font-size: 1.3em;
  line-height: 1.3em;
  vertical-align: top;
  &.status-error,
  &.dropbox-sync-icon-error {
    color: @alert-danger-bg;
  }
  &.status-success,
  &.dropbox-sync-icon-success {
    color: @alert-success-bg;
  }
  &.status-pending,
  &.dropbox-sync-icon-updating {
    color: @alert-info-bg;
    &::after {
      content: '\f021';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -20%;
      font-size: 60%;
      color: #fff;
      animation: fa-spin 2s infinite linear;
    }
  }
}

.settings-widgets-container {
  border: 1px solid @gray-lighter;

  hr {
    margin: 0 10px;
  }
}

.settings-widget-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  > div {
    display: flex;
    flex-direction: column;
    padding-right: 20px;

    &:last-child {
      padding-right: 0px;
    }
  }

  img {
    width: 40px;
    height: 40px;
  }

  .description-container {
    flex-grow: 1;
  }

  .title-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    > h4 {
      margin: 0;
      margin-right: 10px;
    }
  }

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.settings-reconfirm-info {
  display: flex;
  justify-content: space-between;
  margin: 0px auto @margin-sm auto !important;
  padding: @padding-md;
  &:not(.alert-info) {
    background-color: @ol-blue-gray-0;
    .fa-warning {
      color: @brand-warning;
    }
  }

  > *:not(:last-child) {
    margin-right: @margin-md;
  }
}

.setting-reconfirm-info-right {
  white-space: nowrap;
}
