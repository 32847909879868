.online-users {
  display: flex;

  .online-user {
    width: 24px;
    display: inline-block;
    height: 24px;
    margin-right: 8px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    cursor: pointer;
  }

  .online-user-multi {
    color: @toolbar-btn-color;
    width: auto;
    min-width: 24px;
    padding-left: 8px;
    padding-right: 5px;
    display: flex;
    align-items: center;
  }

  .dropdown-menu {
    margin-right: 8px;
    a {
      // Override toolbar link styles
      display: block;
      padding: 4px 10px 5px;
      margin: 1px 2px;
      color: @text-color;
      &:hover,
      &:active {
        color: @text-color!important;
        background-color: @gray-lightest;
        text-shadow: none;
        .box-shadow(none);
      }
    }
  }
}
