.sprite-icon {
  background-image: url('../../../public/img/sprite.png');
}

.sprite-icon-ko {
  background-position: -0px -0px;
  width: 24px;
  height: 24px;
}
.sprite-icon-cn {
  background-position: -0px -24px;
  width: 24px;
  height: 24px;
}
.sprite-icon-da {
  background-position: -0px -48px;
  width: 24px;
  height: 24px;
}
.sprite-icon-de {
  background-position: -0px -72px;
  width: 24px;
  height: 24px;
}
.sprite-icon-en {
  background-position: -0px -96px;
  width: 24px;
  height: 24px;
}
.sprite-icon-es {
  background-position: -0px -120px;
  width: 24px;
  height: 24px;
}
.sprite-icon-fi {
  background-position: -0px -144px;
  width: 24px;
  height: 24px;
}
.sprite-icon-fr {
  background-position: -0px -168px;
  width: 24px;
  height: 24px;
}
.sprite-icon-it {
  background-position: -0px -192px;
  width: 24px;
  height: 24px;
}
.sprite-icon-ja {
  background-position: -0px -216px;
  width: 24px;
  height: 24px;
}
.sprite-icon-cs {
  background-position: -0px -240px;
  width: 24px;
  height: 24px;
}
.sprite-icon-nl {
  background-position: -0px -264px;
  width: 24px;
  height: 24px;
}
.sprite-icon-no {
  background-position: -0px -288px;
  width: 24px;
  height: 24px;
}
.sprite-icon-pl {
  background-position: -0px -312px;
  width: 24px;
  height: 24px;
}
.sprite-icon-pt {
  background-position: -0px -336px;
  width: 24px;
  height: 24px;
}
.sprite-icon-ru {
  background-position: -0px -360px;
  width: 24px;
  height: 24px;
}
.sprite-icon-sv {
  background-position: -0px -384px;
  width: 24px;
  height: 24px;
}
.sprite-icon-tr {
  background-position: -0px -408px;
  width: 24px;
  height: 24px;
}
.sprite-icon-uk {
  background-position: -0px -432px;
  width: 24px;
  height: 24px;
}
.sprite-icon-zh-CN {
  background-position: -0px -456px;
  width: 24px;
  height: 24px;
}
