.system-messages {
  list-style: none;
  margin: 0;
  padding: 0;
}

.system-message {
  padding: (@line-height-computed / 4) (@line-height-computed / 2);
  background-color: @sys-msg-background;
  color: @sys-msg-color;
  border-bottom: @sys-msg-border;
  a {
    color: @sidebar-link-color;
    text-decoration: underline;
  }
}

.system-message .close {
  color: #fff;
  opacity: 1;
  text-shadow: none;
}

.clickable {
  cursor: pointer;
}

.img-circle {
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  width: @line-height-computed * 4;
  height: @line-height-computed * 4;
  img {
    margin-top: -10px;
  }
}

@-webkit-keyframes bounce {
  0%,
  10%,
  26%,
  40%,
  50% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%,
  21% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  35% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce {
  0%,
  10%,
  26%,
  40%,
  50% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%,
  21% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  35% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  50% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bounce {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
}

.grecaptcha-badge {
  visibility: hidden;
  height: 0 !important; // Prevent layout shift
}

.recaptcha-branding {
  padding: @padding-sm @padding-sm 0 @padding-sm;
  text-align: center;
  font-size: @font-size-small;
}

.tos-agreement-notice {
  text-align: center;
  margin-top: (@line-height-computed / 4);
  margin-bottom: 0;
  font-size: @font-size-small;
  &.tos-agreement-notice-homepage {
    margin-top: (@line-height-computed / 2);
    color: #fff;
    & > a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
