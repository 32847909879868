.hotkeys-modal {
  font-size: 14px;

  h3:first-child {
    margin-top: 0;
  }

  .hotkey {
    margin-bottom: @line-height-computed / 2;
  }

  .description {
    display: inline-block;
  }

  .combination {
    padding: 4px 8px;
    border-radius: 3px;
    background-color: @gray-dark;
    color: white;
    font-family: @font-family-sans-serif;
    font-weight: 600;
    margin-right: @line-height-computed / 4;
  }

  .hotkeys-modal-bottom-text {
    background-color: @ol-blue-gray-0;
    padding: @padding-sm;
    border-radius: 5px;
  }
}
