@new-message-height: 80px;

#chat-wrapper {
  > .ui-layout-resizer > .ui-layout-toggler {
    display: none !important;
  }
}

.chat {
  .loading {
    font-family: @font-family-serif;
    padding: @line-height-computed / 2;
    text-align: center;
  }

  .no-messages {
    padding: @line-height-computed / 2;
    color: @chat-instructions-color;
  }

  .first-message {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: @line-height-computed / 2;
    color: @chat-instructions-color;
  }

  .chat-error {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: @chat-bg;
    padding: @line-height-computed / 2;
    text-align: center;
  }

  .messages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: @new-message-height;
    overflow-x: hidden;
    background-color: @chat-bg;

    li.message {
      margin: @line-height-computed / 2;
      .date {
        font-size: 12px;
        color: @chat-message-date-color;
        margin-bottom: @line-height-computed / 2;
        text-align: right;
      }
      .message-wrapper {
        .name {
          font-size: 12px;
          color: @chat-message-name-color;
          margin-bottom: 4px;
          min-height: 16px;
        }
        .message {
          border-left: 3px solid transparent;
          font-size: 14px;
          box-shadow: @chat-message-box-shadow;
          border-radius: @chat-message-border-radius;
          position: relative;

          .message-content {
            padding: @chat-message-padding;
            overflow-x: auto;
            color: @chat-message-color;
            font-weight: @chat-message-weight;
          }

          .arrow {
            transform: rotate(90deg);
            right: 90%;
            top: -15px;
            border: solid;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            border-width: 10px;
          }
        }
        p {
          margin-bottom: @line-height-computed / 4;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:not(.self) {
        .message {
          .arrow {
            border-left-color: transparent !important;
          }
        }
      }

      &.self {
        margin-top: @line-height-computed;
        .message-wrapper .message {
          border-left: none;
          border-right: 3px solid transparent;
          .arrow {
            left: 100%;
            right: auto;
            border-right-color: transparent !important;
          }
        }
      }
    }
  }

  .new-message {
    .full-size;
    top: auto;
    height: @new-message-height;
    background-color: @chat-new-message-bg;
    padding: @line-height-computed / 4;
    border-top: 1px solid @chat-new-message-border-color;
    textarea {
      overflow: auto;
      resize: none;
      border-radius: @border-radius-base;
      border: 1px solid @chat-new-message-border-color;
      height: 100%;
      width: 100%;
      color: @chat-new-message-textarea-color;
      font-size: 14px;
      padding: @line-height-computed / 4;
      background-color: @chat-new-message-textarea-bg;
    }
  }
}

.break-word {
  word-break: break-all;
}
