.MJXp-script {
  font-size: 0.8em;
}
.MJXp-right {
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}
.MJXp-bold {
  font-weight: bold;
}
.MJXp-italic {
  font-style: italic;
}
.MJXp-scr {
  font-family: MathJax_Script, 'Times New Roman', Times, STIXGeneral, serif;
}
.MJXp-frak {
  font-family: MathJax_Fraktur, 'Times New Roman', Times, STIXGeneral, serif;
}
.MJXp-sf {
  font-family: MathJax_SansSerif, 'Times New Roman', Times, STIXGeneral, serif;
}
.MJXp-cal {
  font-family: MathJax_Caligraphic, 'Times New Roman', Times, STIXGeneral, serif;
}
.MJXp-mono {
  font-family: MathJax_Typewriter, 'Times New Roman', Times, STIXGeneral, serif;
}
.MJXp-largeop {
  font-size: 150%;
}
.MJXp-largeop.MJXp-int {
  vertical-align: -0.2em;
}
.MJXp-math {
  display: inline-block;
  line-height: 1.2;
  text-indent: 0;
  font-family: 'Times New Roman', Times, STIXGeneral, serif;
  white-space: nowrap;
  border-collapse: collapse;
}
.MJXp-display {
  display: block;
  text-align: center;
  margin: 1em 0;
}
.MJXp-math span {
  display: inline-block;
}
.MJXp-box {
  display: block !important;
  text-align: center;
}
.MJXp-box:after {
  content: ' ';
}
.MJXp-rule {
  display: block !important;
  margin-top: 0.1em;
}
.MJXp-char {
  display: block !important;
}
.MJXp-mo {
  margin: 0 0.15em;
}
.MJXp-mfrac {
  margin: 0 0.125em;
  vertical-align: 0.25em;
}
.MJXp-denom {
  display: inline-table !important;
  width: 100%;
}
.MJXp-denom > * {
  display: table-row !important;
}
.MJXp-surd {
  vertical-align: top;
}
.MJXp-surd > * {
  display: block !important;
}
.MJXp-script-box > * {
  display: table !important;
  height: 50%;
}
.MJXp-script-box > * > * {
  display: table-cell !important;
  vertical-align: top;
}
.MJXp-script-box > *:last-child > * {
  vertical-align: bottom;
}
.MJXp-script-box > * > * > * {
  display: block !important;
}
.MJXp-mphantom {
  visibility: hidden;
}
.MJXp-munderover,
.MJXp-munder {
  display: inline-table !important;
}
.MJXp-over {
  display: inline-block !important;
  text-align: center;
}
.MJXp-over > * {
  display: block !important;
}
.MJXp-munderover > *,
.MJXp-munder > * {
  display: table-row !important;
}
.MJXp-mtable {
  vertical-align: 0.25em;
  margin: 0 0.125em;
}
.MJXp-mtable > * {
  display: inline-table !important;
  vertical-align: middle;
}
.MJXp-mtr {
  display: table-row !important;
}
.MJXp-mtd {
  display: table-cell !important;
  text-align: center;
  padding: 0.5em 0 0 0.5em;
}
.MJXp-mtr > .MJXp-mtd:first-child {
  padding-left: 0;
}
.MJXp-mtr:first-child > .MJXp-mtd {
  padding-top: 0;
}
.MJXp-mlabeledtr {
  display: table-row !important;
}
.MJXp-mlabeledtr > .MJXp-mtd:first-child {
  padding-left: 0;
}
.MJXp-mlabeledtr:first-child > .MJXp-mtd {
  padding-top: 0;
}
.MJXp-merror {
  background-color: #ffff88;
  color: #cc0000;
  border: 1px solid #cc0000;
  padding: 1px 3px;
  font-style: normal;
  font-size: 90%;
}
.MJXp-scale0 {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
}
.MJXp-scale1 {
  -webkit-transform: scaleX(0.1);
  -moz-transform: scaleX(0.1);
  -ms-transform: scaleX(0.1);
  -o-transform: scaleX(0.1);
  transform: scaleX(0.1);
}
.MJXp-scale2 {
  -webkit-transform: scaleX(0.2);
  -moz-transform: scaleX(0.2);
  -ms-transform: scaleX(0.2);
  -o-transform: scaleX(0.2);
  transform: scaleX(0.2);
}
.MJXp-scale3 {
  -webkit-transform: scaleX(0.3);
  -moz-transform: scaleX(0.3);
  -ms-transform: scaleX(0.3);
  -o-transform: scaleX(0.3);
  transform: scaleX(0.3);
}
.MJXp-scale4 {
  -webkit-transform: scaleX(0.4);
  -moz-transform: scaleX(0.4);
  -ms-transform: scaleX(0.4);
  -o-transform: scaleX(0.4);
  transform: scaleX(0.4);
}
.MJXp-scale5 {
  -webkit-transform: scaleX(0.5);
  -moz-transform: scaleX(0.5);
  -ms-transform: scaleX(0.5);
  -o-transform: scaleX(0.5);
  transform: scaleX(0.5);
}
.MJXp-scale6 {
  -webkit-transform: scaleX(0.6);
  -moz-transform: scaleX(0.6);
  -ms-transform: scaleX(0.6);
  -o-transform: scaleX(0.6);
  transform: scaleX(0.6);
}
.MJXp-scale7 {
  -webkit-transform: scaleX(0.7);
  -moz-transform: scaleX(0.7);
  -ms-transform: scaleX(0.7);
  -o-transform: scaleX(0.7);
  transform: scaleX(0.7);
}
.MJXp-scale8 {
  -webkit-transform: scaleX(0.8);
  -moz-transform: scaleX(0.8);
  -ms-transform: scaleX(0.8);
  -o-transform: scaleX(0.8);
  transform: scaleX(0.8);
}
.MJXp-scale9 {
  -webkit-transform: scaleX(0.9);
  -moz-transform: scaleX(0.9);
  -ms-transform: scaleX(0.9);
  -o-transform: scaleX(0.9);
  transform: scaleX(0.9);
}
.MathJax_PHTML .noError {
  font-size: 90%;
  text-align: left;
  color: black;
  padding: 1px 3px;
  border: 1px solid;
}
