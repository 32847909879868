@project-list-sidebar-wrapper-flex: 0 0 15%;

.project-list-react {
  overflow-x: hidden;

  body > .content& {
    padding-top: @header-height;
    padding-bottom: 0 !important;
    min-height: calc(~'100vh -' @header-height);
  }

  .container:before {
    content: '';
    display: block;
    float: left;
    height: 100%;
  }

  .fill {
    position: relative;

    &:after {
      content: '';
      display: block;
      clear: left;
    }
  }

  .project-list-wrapper {
    display: flex;
    align-items: stretch;
    min-height: calc(~'100vh -' @header-height);
  }

  .project-list-sidebar-wrapper-react {
    background-color: @sidebar-bg;
    flex: @project-list-sidebar-wrapper-flex;
    min-height: calc(~'100vh -' @header-height);

    .project-list-sidebar-subwrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      .project-list-sidebar-react {
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding-top: @content-margin-vertical;
        padding-bottom: @content-margin-vertical;
        color: @sidebar-color;

        .small {
          color: @sidebar-color;
        }

        button {
          white-space: normal;
          word-wrap: anywhere;
          // prevents buttons from expanding sidebar width
        }

        > .dropdown {
          width: 100%;

          .new-project-button {
            width: 100%;
          }
        }
      }
    }
  }

  .project-list-welcome-wrapper {
    width: 100%;
  }

  .project-list-main-react {
    max-width: 100%;
    padding: @content-margin-vertical @grid-gutter-width / 2;
  }

  ul.project-list-filters {
    margin: @margin-sm @folders-menu-margin;

    .subdued {
      color: @gray-light;
    }

    > li {
      cursor: pointer;
      position: relative;

      > button {
        display: block;
        width: 100%;
        font-weight: normal;
        text-align: left;
        color: @sidebar-link-color;
        background-color: transparent;
        border-radius: unset;
        border: none;
        border-bottom: solid 1px transparent;
        padding: @folders-menu-item-v-padding @folders-menu-item-h-padding;

        &:hover {
          background-color: @sidebar-hover-bg;
          text-decoration: @sidebar-hover-text-decoration;
        }

        &:focus {
          text-decoration: none;
          outline: none;
        }
      }

      &.separator {
        padding: @folders-menu-item-v-padding @folders-menu-item-h-padding;
        cursor: auto;
      }
    }

    > li.active {
      border-radius: @sidebar-active-border-radius;

      > button {
        background-color: @sidebar-active-bg;
        font-weight: @sidebar-active-font-weight;
        color: @sidebar-active-color;

        .subdued {
          color: @sidebar-active-color;
        }
      }
    }

    h2 {
      margin-top: @folders-title-margin-top;
      margin-bottom: @folders-title-margin-bottom;
      font-size: @folders-title-font-size;
      color: @folders-title-color;
      text-transform: @folders-title-text-transform;
      padding: @folders-title-padding;
      font-weight: @folders-title-font-weight;
      font-family: @font-family-sans-serif;
    }

    > li.tag {
      &.active {
        .tag-menu > button {
          color: white;
          border-color: white;

          &:hover {
            background-color: @folders-tag-menu-active-hover;
          }
        }
      }

      &.untagged {
        button.tag-name {
          span.name {
            font-style: italic;
            padding-left: 0;
          }
        }
      }

      &:hover {
        &:not(.active) {
          background-color: @folders-tag-hover;
        }

        .tag-menu {
          display: block;
        }
      }

      &:not(.active) {
        .tag-menu > a:hover {
          background-color: @folders-tag-menu-hover;
        }
      }

      button.tag-name {
        position: relative;
        padding: @folders-tag-padding;
        display: @folders-tag-display;
        word-wrap: anywhere;

        span.name {
          padding-left: 0.5em;
          line-height: @folders-tag-line-height;
        }
      }
    }

    .tag-menu {
      button.dropdown-toggle {
        border: 1px solid @folders-tag-border-color;
        border-radius: @border-radius-small;
        background-color: transparent;
        color: @folders-tag-menu-color;
        display: block;
        width: 16px;
        height: 16px;
        position: relative;

        .caret {
          position: absolute;
          top: 6px;
          left: 1px;
        }
      }

      display: none;
      width: auto;
      position: absolute;
      top: 50%;
      margin-top: -8px; // Half the element height.
      right: 4px;

      &.open {
        display: block;
      }

      button.tag-action {
        border-radius: unset;
        width: 100%;
        background-color: transparent;
        border-color: transparent;
        color: @gray-dark;
        text-align: left;
        font-weight: normal;

        &:hover {
          color: @white;
          background-color: @ol-green;
        }

        &:active {
          outline: none;
        }
      }
    }
  }

  .project-dash-table {
    width: 100%;
    table-layout: fixed;

    tr {
      border-bottom: 1px solid @structured-list-border-color;
    }

    th,
    td {
      padding: (@line-height-computed / 4) @line-height-computed / 2;
      vertical-align: top;
    }

    tr {
      th:first-child,
      td:first-child,
      th:last-child,
      td:last-child {
        padding-right: @line-height-computed - (@grid-gutter-width / 2);
      }

      &:hover {
        &:not(.no-projects) {
          background-color: @structured-list-hover-color;
        }
      }
    }

    thead {
      tr:hover {
        background-color: transparent;
      }
    }

    tbody {
      tr:last-child {
        border-bottom: 0 none;
      }
    }

    .table-header-sort-btn {
      border: 0;
      text-align: left;
      color: @ol-type-color;
      padding: 0;
      font-weight: bold;

      &:hover,
      &:focus {
        color: @ol-type-color;
        text-decoration: none;
      }
    }

    .dash-cell-checkbox {
      width: 5%;

      input[type='checkbox'] {
        margin-top: 5px;
      }
    }

    .dash-cell-name {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dash-cell-owner {
      width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dash-cell-date {
      width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dash-cell-actions {
      display: none;
      text-align: right;
      white-space: nowrap;
    }

    .dash-cell-date-owner {
      font-size: 14px;
      .text-overflow();
    }

    .dash-cell-tag {
      .tag-label {
        padding: 14px 0;
        cursor: pointer;

        &:not(.tag-label-close-hover) {
          &:hover,
          &:focus {
            .label.tag-label-name {
              color: @tag-color;
              background-color: @tag-bg-hover-color;
              outline-width: 0;
            }
          }
        }

        &:first-child {
          margin-left: initial;
        }
      }
    }

    @media (min-width: @screen-xs) {
      .dash-cell-checkbox {
        width: 4%;
      }

      .dash-cell-name {
        width: 50%;
      }

      .dash-cell-owner {
        width: 21%;
      }

      .dash-cell-date {
        width: 25%;
      }

      .dash-cell-actions {
        width: 0%;
      }
    }

    @media (min-width: @screen-sm) {
      .dash-cell-checkbox {
        width: 3%;
      }

      .dash-cell-name {
        width: 48%;
      }

      .dash-cell-owner {
        width: 13%;
      }

      .dash-cell-date {
        width: 15%;
      }

      .dash-cell-actions {
        display: table-cell;
        width: 21%;
      }

      .project-tools {
        float: none;
      }
    }

    @media (min-width: @screen-md) {
      .dash-cell-checkbox {
        width: 3%;
      }

      .dash-cell-name {
        width: 50%;
      }

      .dash-cell-owner {
        width: 13%;
      }

      .dash-cell-date {
        width: 16%;
      }

      .dash-cell-actions {
        width: 18%;
      }
    }

    @media (min-width: @screen-lg) {
      .dash-cell-checkbox {
        width: 3%;
      }

      .dash-cell-name {
        width: 50%;
      }

      .dash-cell-owner {
        width: 15%;
      }

      .dash-cell-date {
        width: 19%;
      }

      .dash-cell-actions {
        width: 13%;
      }
    }

    @media (min-width: 1440px) {
      .dash-cell-checkbox {
        width: 2%;
      }

      .dash-cell-name {
        width: 50%;
      }

      .dash-cell-owner {
        width: 16%;
      }

      .dash-cell-date {
        width: 19%;
      }

      .dash-cell-actions {
        width: 13%;
      }
    }

    @media (max-width: @screen-xs-max) {
      @actions-btn-size: 48px;

      tr {
        position: relative;
        display: flex;
        flex-direction: column;

        td {
          padding-top: @line-height-computed / 6;
          padding-bottom: @line-height-computed / 6;
        }

        td:not(.dash-cell-actions) {
          padding-right: @actions-btn-size + 12.5px;
        }
      }

      .dash-cell-name,
      .dash-cell-owner,
      .dash-cell-date,
      .dash-cell-tag,
      .dash-cell-actions {
        display: block;
        width: auto;
      }

      .dash-cell-actions {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 !important;

        .dropdown-toggle {
          padding: 13px 15px;
          border-radius: 0;
          font-size: 14px;
        }
      }
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    min-height: calc(~'100vh -' @header-height);

    .loading-screen-brand-container {
      margin: 0 auto;
    }
  }

  @media (max-width: @screen-xs-max) {
    .project-tools {
      float: left;
    }
  }

  .projects-toolbar,
  .tag-item {
    display: flex;
    align-items: center;
  }

  .projects-toolbar {
    .dropdown,
    .dropdown-toggle {
      max-width: 100%;
    }

    .dropdown {
      min-width: 0;
    }

    .dropdown-toggle {
      display: inline-flex;
      align-items: baseline;
    }
  }

  .projects-sort-dropdown {
    flex-shrink: 0;
    margin-left: auto;
  }

  #projects-types-dropdown {
    font-family: @font-family-serif;

    & + .projects-dropdown-menu {
      min-width: 226px;
    }
  }

  #projects-sort-dropdown {
    & + .projects-dropdown-menu {
      min-width: 156px;
    }
  }

  .projects-dropdown-menu {
    .dropdown-header {
      padding: 14px 20px;
      font-size: 13px;
      text-transform: uppercase;
    }
  }

  .projects-types-menu-item {
    .menu-item-button-icon {
      left: 10px;
    }

    &.projects-types-menu-tag-item {
      display: flex;

      .edit-btn {
        padding: 12px 14px;
      }
    }
  }

  .projects-sort-menu-item {
    .menu-item-button-icon {
      left: 14px;
    }
  }

  .projects-action-menu-item {
    .menu-item-button-icon {
      left: 11px;
    }
  }
}

body.thin-footer {
  .project-list-main-react {
    padding-bottom: @footer-height;
  }
}

.current-plan {
  vertical-align: middle;
  line-height: @line-height-base;

  a.current-plan-label {
    text-decoration: none;
    color: @text-color;
  }
}

.project-list-upload-project-modal-uppy-dashboard .uppy-Root {
  .uppy-Dashboard-AddFiles-title {
    display: flex;
    flex-direction: column;
    color: @ol-blue-gray-3;
    white-space: pre-line;

    button.uppy-Dashboard-browse {
      background-color: @ol-green;
      color: @white;
      margin-bottom: @margin-md;
      padding: 4px 16px 5px;
      height: 46px;
      border-radius: 23px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: @font-size-large;
      font-family: @font-family-sans-serif;
    }
  }
}

.btn-transparent {
  box-shadow: none !important;
  background: none !important;
  border-radius: 0 !important;
  color: inherit !important;
  font-weight: 400;

  &:hover {
    box-shadow: none !important;
    background: none !important;
    color: inherit !important;
  }
}

.menu-item-button,
#new-project-button-projects-table + .dropdown-menu [role='menuitem'] {
  padding: 12px 20px;
}

.menu-item-button {
  position: relative;
  width: 100%;
  border: none;
  box-shadow: none;
  background: inherit;
  color: @ol-blue-gray-5;
  text-align: left;

  .menu-item-button-text {
    padding-left: 14px;
  }

  .menu-item-button-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.project-list-load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-list-load-more-button {
  margin-bottom: @margin-sm;
}

.tag-dropdown-button {
  display: block;
  width: 100%;
  font-weight: normal;
  font-size: @font-size-base;
  text-align: left;
  color: @gray-dark;
  background-color: transparent;
  border-radius: unset;
  border: none;
  border-bottom: solid 1px transparent;
  padding: 3px 20px;

  &:active,
  &:focus {
    color: @gray-dark;
    background-color: transparent;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    color: @white;
    background-color: @ol-green;
  }
}

.project-list-sidebar-survey-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 15%;

  .project-list-sidebar-survey-dismiss {
    position: relative;
    z-index: 1;
    float: right;
  }

  @media (max-width: @screen-xs-max) {
    line-height: @mobile-action-element-height;
    position: static;
    display: block;
    margin-top: @margin-sm;
    width: initial;

    .project-list-sidebar-survey {
      font-size: unset;

      &:before {
        display: none;
      }

      .project-list-sidebar-survey-link {
        display: inline-flex;
        align-items: center;
        min-width: @mobile-action-element-width;
        min-height: @mobile-action-element-height;
      }
    }

    .project-list-sidebar-survey-dismiss {
      width: @mobile-action-element-width;
      height: @mobile-action-element-height;
      border: 0;
    }
  }
}

.projects-action-list {
  list-style: none;

  li:not(.completed-project-action) {
    list-style-type: disc;
  }

  li.completed-project-action {
    &:before {
      display: inline-block;
      content: '\f00c';
      font-family: FontAwesome;
      color: @ol-green;
      width: 1.2em;
      margin-left: -1.2em;
      height: 0; // prevent slight vertical layout shift
    }
  }
}
