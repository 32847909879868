@brand-ieee-color: #00629b;
@brand-google-color: #276fea; // Not the "official" color but modified for a11y
@brand-twitter-color: #1da1f2;
@brand-orcid-color: #a6ce39;
@brand-sharelatex-color: #a93529;

.login-register-alternatives {
  .form-group:last-child {
    margin-bottom: 0;
  }
}

.login-register-container {
  max-width: 400px;
  margin: 0 auto;
}
.login-register-header {
  padding-top: @line-height-computed;
  padding-bottom: @line-height-computed - 5;
  border-bottom: solid 1px @hr-border;
}
.login-register-header-heading {
  margin: 0;
  color: @text-color;
}

.login-register-card {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

.login-register-form,
.login-register-sharelatex {
  padding: @line-height-computed;
  border-bottom: solid 1px @hr-border;
  &:last-child {
    border-bottom-width: 0;
  }
}
.login-register-other-links {
  padding: @line-height-computed;
}

.login-register-text,
.login-register-hr-text-container {
  margin: 0;
}

.login-register-text {
  padding-bottom: 25px;
  &:last-child {
    padding-bottom: 0;
  }
}

.login-register-hr-text-container {
  line-height: 1;
  position: relative;
  padding: @line-height-computed 0;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: @hr-border;
    top: 50%;
    left: 0;
    right: 0;
  }
}
.login-register-hr-text {
  position: relative;
  background-color: #fff;
  padding: 0 (@line-height-computed / 2);
}
.login-register-sharelatex-tooltip {
  display: inline-block;
  font-size: 135%;
  position: relative;
  top: 2px;
  margin-left: 3px;
  color: @link-color;
  cursor: pointer;
}

.login-register-newsletter {
  font-size: 90%;
  margin-top: @line-height-computed;
  text-align: left;

  & > .checkbox {
    margin-bottom: 0;
  }
}

.login-btn {
  .btn;
  .btn-default;
  position: relative;
  padding-left: 20px;
  padding-right: 0;
}

.login-btn-sharelatex {
  background-color: @brand-sharelatex-color;
  &:focus,
  &:hover {
    background-color: darken(@brand-sharelatex-color, 8%);
  }
  &:active {
    background-color: darken(@brand-sharelatex-color, 16%);
  }
}

.login-btn-icon {
  position: absolute;
  top: 4px;
  left: 4px;
  background: #fff url(../../../public/img/brand/lion.svg) center/20px no-repeat;
  border-radius: 99999px;
  width: 26px;
  height: 26px;

  &::before {
    content: '\00a0'; // Non-breakable space. A non-breakable character here makes this icon work like font-awesome.
  }
}

.login-btn-icon-ieee {
  background-image: url(../../../public/img/other-brands/logo_ieee.svg);
}
.login-btn-icon-google {
  background-image: url(../../../public/img/other-brands/logo_google.svg);
}
.login-btn-icon-twitter {
  background-image: url(../../../public/img/other-brands/logo_twitter.svg);
}
.login-btn-icon-orcid {
  background-image: url(../../../public/img/other-brands/logo_orcid.svg);
}
.login-btn-icon-sharelatex {
  background-size: 22px;
}

.login-register-header-heading {
  margin: 0;
  color: @text-color;
}

.registration-message-heading {
  color: @text-color;
}

.registration-message-details {
  font-size: 90%;
}

.registration-block-separator {
  margin-bottom: 0px;
}
