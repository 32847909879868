@import (less) '../fonts/lato.css';
@import (less) '../fonts/merriweather.css';
@import (less) '../fonts/source-code-pro.css';
@import (less) '../fonts/stix-two-math.css';

@import (less) 'vendor/angular-csp.css';

@is-new-css: false;

// Core variables and mixins
@import 'core/variables.less';
@import 'core/css-variables.less';
@import 'core/page.less';
@import 'app/ol-style-guide.less';
@import '_style_includes.less';
@import '_ol_style_includes.less';
@import 'components/embed-responsive.less';
@import 'components/icons.less';
@import 'components/images.less';
@import 'components/navs-ol.less';
@import 'components/pagination.less';
@import 'components/tabs.less';
// SAAS: @import 'components/input-switch.less';

// Pages
// SAAS: @import 'app/about.less';
// SAAS: @import 'app/blog-posts.less';
// SAAS: @import 'app/cms-page.less';
@import 'app/content_page.less';
// SAAS: @import 'app/plans-ol.less';
// SAAS: @import 'app/portals.less';
