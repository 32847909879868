//
// Accessibility
// --------------------------------------------------

// For improving accessibility.
// If possible, add accessibiity styling to stylesheet where selector is
// defined. Otherwise, add here.

// <del> styling taken from:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/del#Accessibility_concerns
del::before,
del::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
del::before {
  content: ' [deletion start] ';
}

del::after {
  content: ' [deletion end] ';
}
