footer.site-footer {
  background-color: @footer-bg-color;
  border-top: 1px solid @gray-lighter;
  font-size: 0.9rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: @footer-height;
  line-height: @footer-height - 1; // Hack — in Chrome, using the full @footer-height would generate vertical scrolling

  ul {
    list-style: none;
    margin: 0px;
    li {
      display: inline-block;
      margin: 0 0.5em;
    }
    i {
      font-size: 1.2rem;
    }
  }
  li.lngOption {
    text-align: left;
    display: list-item;

    img {
      vertical-align: text-bottom;
    }

    a:hover,
    a:focus {
      color: @white;
    }
  }

  a {
    color: @footer-link-color;
    &:hover,
    &:focus {
      color: @footer-link-hover-color;
    }
  }
}

.site-footer-content {
  .container-fluid;
}

.sprite-icon-lang {
  display: inline-block;
  vertical-align: middle;
}

.fat-footer {
  background: @ol-blue-gray-6;
  color: @ol-blue-gray-1;
  display: flex;
  flex-direction: column;

  .fat-footer-container {
    margin: @margin-xxl auto;
  }

  a {
    color: @ol-blue-gray-1;
  }

  .footer-brand-container {
    flex: 1;
  }

  .fat-footer-sections {
    display: grid;
    column-gap: @margin-md;
    padding: 0 @padding-md @padding-md;
  }

  .footer-brand {
    display: block;
    height: 37px; /* TODO: configurable? */
    width: @navbar-brand-width;
    background-image: @navbar-brand-image-url;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
  }

  .footer-section-heading {
    font-family: @font-family-serif;
    font-size: @font-size-h3;
    line-height: @headings-line-height;
    margin-bottom: @margin-md;
    margin-top: @margin-md;
  }

  .footer-section ul {
    font-family: @font-family-sans-serif;
    font-size: @font-size-base;
    line-height: @line-height-small;
  }

  .footer-section li {
    padding-bottom: @padding-sm;
  }

  #footer-brand {
    grid-column: ~'1/-1';
    margin-top: @margin-md;
  }

  .fat-footer-base {
    color: @ol-blue-gray-2;
    font-size: @font-size-small;
    line-height: @line-height-base;

    .fat-footer-base-meta a:not(.dropdown-toggle) {
      color: inherit;
    }

    .language-picker .dropdown-menu {
      .dropdown-header {
        display: none; /* hiding rather than removing as still needed in the thin footer */
      }

      a {
        color: @gray-dark;

        &:hover {
          color: white;
        }
      }
    }
  }

  .fat-footer-base-item {
    display: flex;
    white-space: nowrap;
  }

  @media (max-width: @screen-xs-max) {
    .fat-footer-sections {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
    }

    .fat-footer-base {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: @margin-md auto 0;

      .fat-footer-base-section {
        display: flex;
        align-items: center;
      }

      .fat-footer-base-social {
        order: 1;
      }

      .fat-footer-base-meta {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        order: 2;
      }

      .fat-footer-base-item {
        padding: @padding-xs;
        margin: @margin-lg / 2;
      }

      .fat-footer-base-meta .fat-footer-base-item {
        gap: @margin-lg;
      }

      .fat-footer-base-social .fat-footer-base-item {
        gap: @margin-md;
      }

      .fat-footer-social {
        font-size: @fat-footer-social-font-size;
      }

      .fat-footer-base-copyright {
        order: 2;
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    .fat-footer-container {
      width: (@screen-sm - (@padding-sm * 2));
    }

    #footer-brand {
      grid-column: auto;
      grid-row: ~'1/-1';
    }

    .fat-footer-sections {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
    }

    .footer-section:last-of-type {
      grid-column: 4;
    }

    .footer-section ul {
      line-height: 20px;
    }

    .fat-footer-base {
      display: flex;
      justify-content: space-between;
      margin: @margin-md auto;

      .fat-footer-base-section {
        display: flex;
        align-items: center;
      }

      .fat-footer-base-item {
        padding: @padding-xs;
        margin: @margin-xs;
      }

      .fat-footer-base-meta .fat-footer-base-item {
        gap: @margin-md;
      }

      .fat-footer-social {
        margin: 0 @margin-xs;
        font-size: @fat-footer-social-font-size;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .fat-footer-container {
      width: (@screen-md - @padding-md);
    }

    .fat-footer-sections {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: @screen-lg-min) {
    .fat-footer-container {
      width: (@screen-lg - @padding-lg);
    }

    .fat-footer-sections {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto;
    }

    .footer-section:last-of-type {
      grid-column: 6;
    }
  }
}

.cookie-banner {
  padding: 10px 20px;
  font-size: 0.9rem;
  line-height: 1;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 100;
  color: @text-color;
  background: @ol-blue-gray-0;
  box-shadow: 0px -5px 8px 0px #0000001a;

  .cookie-banner-actions {
    padding-top: 10px;
  }

  @media (min-width: @screen-sm-min) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .cookie-banner-content {
      flex: 1;
    }

    .cookie-banner-actions {
      flex-shrink: 0;
      white-space: nowrap;
      padding-top: 0;
    }
  }
}
