@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('lato/lato-v2-latin-ext-regular.woff2') format('woff2'),
    url('lato/lato-v2-latin-ext-regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('lato/lato-v2-latin-ext-italic.woff2') format('woff2'),
    url('lato/lato-v2-latin-ext-italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('lato/lato-v2-latin-ext-700.woff2') format('woff2'),
    url('lato/lato-v2-latin-ext-700.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('lato/lato-v2-latin-ext-700italic.woff2') format('woff2'),
    url('lato/lato-v2-latin-ext-700italic.woff') format('woff');
}
