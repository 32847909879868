#MathJax_About {
  position: fixed;
  left: 50%;
  width: auto;
  text-align: center;
  border: 3px outset;
  padding: 1em 2em;
  background-color: #dddddd;
  color: black;
  cursor: default;
  font-family: message-box;
  font-size: 120%;
  font-style: normal;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  z-index: 201;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px;
  box-shadow: 0px 10px 20px #808080;
  -webkit-box-shadow: 0px 10px 20px #808080;
  -moz-box-shadow: 0px 10px 20px #808080;
  -khtml-box-shadow: 0px 10px 20px #808080;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true');
}
#MathJax_About.MathJax_MousePost {
  outline: none;
}
.MathJax_Menu {
  position: absolute;
  background-color: white;
  color: black;
  width: auto;
  padding: 5px 0px;
  border: 1px solid #cccccc;
  margin: 0;
  cursor: default;
  font: menu;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  z-index: 201;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  box-shadow: 0px 10px 20px #808080;
  -webkit-box-shadow: 0px 10px 20px #808080;
  -moz-box-shadow: 0px 10px 20px #808080;
  -khtml-box-shadow: 0px 10px 20px #808080;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true');
}
.MathJax_MenuItem {
  padding: 1px 2em;
  background: transparent;
}
.MathJax_MenuArrow {
  position: absolute;
  right: 0.5em;
  padding-top: 0.25em;
  color: #666666;
  font-size: 0.75em;
}
.MathJax_MenuActive .MathJax_MenuArrow {
  color: white;
}
.MathJax_MenuArrow.RTL {
  left: 0.5em;
  right: auto;
}
.MathJax_MenuCheck {
  position: absolute;
  left: 0.7em;
}
.MathJax_MenuCheck.RTL {
  right: 0.7em;
  left: auto;
}
.MathJax_MenuRadioCheck {
  position: absolute;
  left: 0.7em;
}
.MathJax_MenuRadioCheck.RTL {
  right: 0.7em;
  left: auto;
}
.MathJax_MenuLabel {
  padding: 1px 2em 3px 1.33em;
  font-style: italic;
}
.MathJax_MenuRule {
  border-top: 1px solid #dddddd;
  margin: 4px 3px;
}
.MathJax_MenuDisabled {
  color: GrayText;
}
.MathJax_MenuActive {
  background-color: #606872;
  color: white;
}
.MathJax_MenuDisabled:focus,
.MathJax_MenuLabel:focus {
  background-color: #e8e8e8;
}
.MathJax_ContextMenu:focus {
  outline: none;
}
.MathJax_ContextMenu .MathJax_MenuItem:focus {
  outline: none;
}
#MathJax_AboutClose {
  top: 0.2em;
  right: 0.2em;
}
.MathJax_Menu .MathJax_MenuClose {
  top: -10px;
  left: -10px;
}
.MathJax_MenuClose {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #aaa;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -khtml-border-radius: 18px;
  font-family: 'Courier New', Courier;
  font-size: 24px;
  color: #f0f0f0;
}
.MathJax_MenuClose span {
  display: block;
  background-color: #aaa;
  border: 1.5px solid;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -khtml-border-radius: 18px;
  line-height: 0;
  padding: 8px 0 6px;
}
.MathJax_MenuClose:hover {
  color: white !important;
  border: 2px solid #ccc !important;
}
.MathJax_MenuClose:hover span {
  background-color: #ccc !important;
}
.MathJax_MenuClose:hover:focus {
  outline: none;
}
