//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.alert {
  padding: @alert-padding;
  margin-bottom: @line-height-computed;
  border-left: 3px solid transparent;
  border-radius: @alert-border-radius;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing @headings-color
    color: inherit;
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: @alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

// Dismissable alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable {
  padding-right: (@alert-padding + 20);

  // Adjust close link position
  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
}

.btn-alert-success {
  .btn-alert-variant(@alert-success-bg);
}

.alert-info {
  .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
}

.btn-alert-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
  .alert-btn(@btn-info-bg);
}

.alert-warning {
  .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
}

.btn-alert-warning {
  .btn-alert-variant(@alert-warning-bg);
}

.alert-danger {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}

.btn-alert-danger {
  .btn-alert-variant(@alert-danger-bg);
}

.alert-alt {
  .alert-variant(@alert-alt-bg; @alert-alt-border; @alert-alt-text);
}

.btn-alert-alt {
  .btn-alert-variant(@alert-alt-bg);
}

.alert {
  a,
  .btn-inline-link {
    color: white;
    text-decoration: underline;
    background: none;
    &:hover,
    &:focus,
    &:active {
      background: none;
    }
  }
  .btn:not(.btn-inline-link) {
    text-decoration: none;
  }
}
