#dictionary-modal {
  .modal-body {
    padding: 0;
  }
}

.dictionary-entries-list {
  overflow-y: scroll;
  max-height: calc(100vh - 225px);
  margin: 0;
  padding: @padding-sm;
}

.dictionary-entry {
  word-break: break-all;
  display: flex;
  padding: @padding-sm;
  border-bottom: solid 1px @modal-header-border-color;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
}

.dictionary-entry-name {
  flex-grow: 1;
  padding-right: @padding-xs;
}

.dictionary-empty-body {
  padding: @padding-md;
}
