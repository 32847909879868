.info-badge,
.beta-badge,
.alpha-badge {
  display: inline-block;
  width: @line-height-computed * 0.75;
  height: @line-height-computed * 0.75;
  line-height: @font-size-small;
  font-size: @line-height-computed * 0.75;
  font-weight: normal;
  text-align: center;
  color: #fff;

  &::before {
    line-height: @font-size-small;
    font-size: @font-size-small;
  }

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

.info-badge {
  background-color: @info-badge-bg;
  border-radius: @line-height-computed * 0.75;
  font-family: @font-family-serif;
  font-style: italic;

  &::before {
    content: 'i';
  }
}

.info-badge-fade-bg {
  .info-badge;
  background-color: rgba(0, 0, 0, 0.25);
}

.beta-badge {
  background-color: @orange;
  border-radius: @border-radius-base;

  &::before {
    content: 'β';
  }
}
.alpha-badge {
  background-color: @ol-green;
  border-radius: @border-radius-base;

  &::before {
    content: 'α';
  }
}

.labs-badge {
  background-color: @orange;
  border-radius: @border-radius-base;
  padding: 2px;
  color: white;
}

.split-test-badge-tooltip .tooltip-inner {
  white-space: pre-wrap;
}

.tooltip-wide .tooltip-inner {
  min-width: 275px;
}
