@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
    url('merriweather-v21-latin-regular.woff2') format('woff2'),
    url('merriweather-v21-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: local('Merriweather Italic'), local('Merriweather-Italic'),
    url('merriweather-v21-latin-italic.woff2') format('woff2'),
    url('merriweather-v21-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
    url('merriweather-v21-latin-700.woff2') format('woff2'),
    url('merriweather-v21-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
    url('merriweather-v21-latin-700italic.woff2') format('woff2'),
    url('merriweather-v21-latin-700italic.woff') format('woff');
}
