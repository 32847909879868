.renderColorSwatchClasses(@colorName) {
  @colorVal: @@colorName;
  @colorValRed: red(@colorVal);
  @colorValGreen: green(@colorVal);
  @colorValBlue: blue(@colorVal);
  @colorValAsRGB: 'rgb(@{colorValRed}, @{colorValGreen}, @{colorValBlue})';

  &.@{colorName} {
    .color-swatch {
      background-color: @colorVal;
    }
    .color-less-var::before {
      content: '@@{colorName}';
    }
    .color-hex-val::before {
      content: '@{colorVal}';
    }
    .color-rgb-val::before {
      font-size: 10px;
      content: '@{colorValAsRGB}';
    }
  }
}

.color-row {
  display: flex;
  justify-content: space-between;
}

.color-box {
  background: white;
  margin: 10px 4px;
  border-radius: 4px;
  width: 16.666%;
  .renderColorSwatchClasses(ol-blue-gray-1);
  .renderColorSwatchClasses(ol-blue-gray-2);
  .renderColorSwatchClasses(ol-blue-gray-3);
  .renderColorSwatchClasses(ol-blue-gray-4);
  .renderColorSwatchClasses(ol-blue-gray-5);
  .renderColorSwatchClasses(ol-blue-gray-6);
  .renderColorSwatchClasses(ol-green);
  .renderColorSwatchClasses(ol-dark-green);
  .renderColorSwatchClasses(ol-blue);
  .renderColorSwatchClasses(ol-dark-blue);
  .renderColorSwatchClasses(ol-red);
  .renderColorSwatchClasses(ol-dark-red);
}

.color-swatch {
  height: 100px;
  width: 100px;
  margin: 10px auto;
  border-radius: 4px;
}

.color-label {
  display: flex;
  flex-direction: column;
  margin: 0 3px 10px;
}

.color-label pre {
  font-size: 12px;
  line-height: 1.8em;
  margin: 0 auto;
}
