#MathJax_Zoom {
  position: absolute;
  background-color: #f0f0f0;
  overflow: auto;
  display: block;
  z-index: 301;
  padding: 0.5em;
  border: 1px solid black;
  margin: 0;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  box-shadow: 5px 5px 15px #aaaaaa;
  -webkit-box-shadow: 5px 5px 15px #aaaaaa;
  -moz-box-shadow: 5px 5px 15px #aaaaaa;
  -khtml-box-shadow: 5px 5px 15px #aaaaaa;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true');
}
#MathJax_ZoomOverlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 300;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}
#MathJax_ZoomFrame {
  position: relative;
  display: inline-block;
  height: 0;
  width: 0;
}
#MathJax_ZoomEventTrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 302;
  display: inline-block;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}
