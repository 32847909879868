.file-view,
.binary-file {
  padding: @line-height-computed / 2;
  background-color: @gray-lightest;
  text-align: center;
  overflow: auto;
  img {
    max-width: 100%;
    max-height: 90%;
    display: block;
    margin: auto;
    margin-top: @line-height-computed / 2;
    border: 1px solid @gray;
    .box-shadow(0 2px 3px @gray;);
    background-color: white;
  }
  p.no-preview {
    margin-top: @line-height-computed / 2;
    font-size: 24px;
    color: @gray;
  }
  .text-loading {
    margin-top: @line-height-computed / 2;
    font-size: 24px;
    color: @gray;
  }
  .text-preview {
    margin-top: @line-height-computed / 2;
    .scroll-container {
      background-color: white;
      font-size: 0.8em;
      line-height: 1.1em;
      overflow: auto;
      border: 1px solid @gray-lighter;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: left;
      white-space: pre;
      font-family: monospace;
    }
  }
  .linked-file-icon {
    color: @blue;
  }
}
