.logs-pane {
  .full-size;
  top: @pdf-top-offset;
  overflow-y: auto;
  background-color: @logs-pane-bg;
}

.logs-pane-content {
  display: flex;
  flex-direction: column;
  padding: @padding-sm;
  min-height: 100%;
}

.logs-pane-actions {
  display: flex;
  justify-content: flex-end;
  padding: @padding-sm 0;
  flex-grow: 1;
  align-items: flex-end;
}

.logs-pane-actions-clear-cache {
  .no-outline-ring-on-click;
  margin-right: @margin-sm;
}

.log-entry {
  margin-bottom: @margin-sm;
  border-radius: @border-radius-base;
  overflow: hidden;
}

.log-entry-first-error-popup {
  border-radius: 0;
  overflow: auto;
}

.log-entry-header {
  padding: 3px @padding-sm;
  display: flex;
  align-items: flex-start;
  border-radius: @border-radius-base @border-radius-base 0 0;
  color: #fff;
}

.log-entry-header-error {
  background-color: @ol-red;
}

.log-entry-header-link-error {
  .btn-alert-variant(@ol-red);
}

.log-entry-header-warning {
  background-color: @orange;
}

.log-entry-header-link-warning {
  .btn-alert-variant(@orange);
}

.log-entry-header-typesetting {
  background-color: @ol-blue;
}

.log-entry-header-link-typesetting {
  .btn-alert-variant(@ol-blue);
}

.log-entry-header-raw,
.log-entry-header-info {
  background-color: @ol-blue-gray-4;
}

.log-entry-header-link-raw,
.log-entry-header-link-info {
  .btn-alert-variant(@ol-blue-gray-4);
}

.log-entry-header-success {
  background-color: @green;
}

.log-entry-header-link-success {
  .btn-alert-variant(@green);
}

.log-entry-header-title,
.log-entry-header-link {
  font-family: @font-family-sans-serif;
  font-size: @font-size-base;
  line-height: @line-height-base;
  font-weight: 700;
  flex-grow: 1;
  margin: 0;
  color: #fff;
}

.log-entry-header-icon-container {
  margin-right: @margin-sm;
}

.log-entry-header-link {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  border-width: 0;
  flex-grow: 0;
  text-align: right;
  margin-left: @margin-sm;
  max-width: 33%;
  padding: 0 @padding-xs;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
    color: #fff;
  }
  &:focus {
    text-decoration: none;
  }
}

.log-entry-header-link-location {
  white-space: nowrap;
  direction: rtl;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 @padding-xs;
}

.log-entry-content {
  background-color: #fff;
  padding: @padding-sm;
}

.log-entry-content-raw-container {
  background-color: @ol-blue-gray-1;
  border-radius: @border-radius-base;
  overflow: hidden;
  margin-top: @margin-sm;
}

.log-entry-content-raw {
  font-size: @font-size-extra-small;
  color: @ol-blue-gray-4;
  padding: @padding-sm;
  margin: 0;
}

.log-entry-content-button-container {
  position: relative;
  height: 40px;
  margin-top: 0;
  transition: margin 0.15s ease-in-out, opacity 0.15s ease-in-out;
  padding-bottom: @padding-sm;
  text-align: center;
  background-image: linear-gradient(0, @ol-blue-gray-1, transparent);
  border-radius: 0 0 @border-radius-base @border-radius-base;
}
.log-entry-content-button-container-collapsed {
  margin-top: -40px;
}

.log-entry-btn-expand-collapse {
  position: relative;
  z-index: 1;
  .no-outline-ring-on-click;
}

.log-entry-formatted-content,
.log-entry-content-link {
  font-size: @font-size-small;
  margin-top: @margin-xs;
  &:first-of-type {
    margin-top: 0;
  }
}

.log-entry-formatted-content-list {
  margin: @margin-xs 0;
  padding-left: @padding-md;
}

.first-error-popup {
  position: absolute;
  z-index: 1;
  top: @toolbar-small-height + 2px;
  right: @padding-xs;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  animation: fade-in 0.15s linear 0s 1 none;
  background-color: #fff;
  &::before {
    content: '';
    .triangle(top, @padding-sm, @padding-xs, @ol-red);
    top: -@padding-xs;
    right: @padding-xl;
  }
}

.first-error-popup-actions {
  display: flex;
  justify-content: space-between;
  padding: 0 @padding-sm @padding-sm @padding-sm;
  border-radius: 0 0 @border-radius-base @border-radius-base;
}

.first-error-btn {
  .no-outline-ring-on-click;
}

.log-location-tooltip {
  word-break: break-all;
  &.tooltip.in {
    opacity: 1;
  }
  & > .tooltip-inner {
    max-width: 450px;
    text-align: left;
  }
}
