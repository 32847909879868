.full-height {
  height: 100%;
  padding: 0;
}

.error-container {
  display: flex;
  align-items: center;
}

.error-container.full-height {
  margin-top: -(@header-height + @content-margin-vertical) / 2;
}

.error-details {
  flex: 1 1 50%;
  padding: @line-height-computed;
  max-width: 90%;
  @media (min-width: @screen-sm-min) {
    padding: @line-height-computed * 2;
  }
}

.error-status {
  font-family: @font-family-serif;
  margin-bottom: @line-height-computed;
  color: @gray-dark;
  font-size: @font-size-h1;
}
.error-description {
  font-family: @font-family-serif;
  font-size: @font-size-h3;
  color: @gray;
  margin-bottom: @line-height-computed;
}
.error-box {
  background-color: @ol-blue-gray-1;
  padding: @margin-sm;
  font-family: @font-family-monospace;
  margin-bottom: @line-height-computed;
}
.error-actions {
  margin-top: @line-height-computed * 2;
}

.error-btn {
  .btn;
  .btn-primary;
  display: block;
  @media (min-width: @screen-sm-min) {
    display: inline-block;
  }
}
