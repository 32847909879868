.wiki {
  .contents {
    ul {
      padding: 0;
      li {
        font-size: 14px;
        list-style: none;
        margin-bottom: 6px;
      }
    }

    h2 {
      font-size: 18px;
    }
  }

  .page-header {
    a {
      font-size: 0.8em;
      line-height: 1;
    }
  }

  .editsection {
    display: none;
  }

  .toctogglespan {
    display: none;
  }
  .toc {
    ul {
      list-style: none;
      .tocnumber::after {
        content: '.';
      }
    }
  }

  table {
    th,
    td {
      padding: (@line-height-computed / 4) (@line-height-computed / 2);
      border-bottom: 1px solid @gray-lighter;
    }
    th {
      font-weight: 700;
      text-align: left;
      font-family: @font-family-serif;
    }
    margin-bottom: @line-height-computed / 2;
  }
  .table-no-borders {
    th,
    td {
      border: 0px;
    }
  }

  .example {
    max-width: 100%;

    .code {
      pre {
        background-color: @gray-lightest;
        border-radius: 6px;
        padding: (@line-height-computed / 2);
        white-space: pre-wrap;
        margin: 0;
      }
    }
    .output {
      text-align: center;
      padding-top: 10px;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        box-shadow: 0 1px 3px @gray-light;
        border-radius: 6px;
      }
    }
  }
  @media (min-width: 1360px) {
    .example {
      margin-right: -200px;
    }
  }
  @media (max-width: @screen-sm) {
    .contents {
      margin-top: @margin-lg;
    }
  }

  /*<![CDATA[*/
  .source-latex {
    line-height: normal;
  }
  .source-latex li,
  .source-latex pre {
    line-height: normal;
    border: 0px none white;
  }
  /**
	 * GeSHi Dynamically Generated Stylesheet
	 * --------------------------------------
	 * Dynamically generated stylesheet for latex
	 * CSS class: source-latex, CSS id:
	 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
	 * (http://qbnz.com/highlighter/ and http://geshi.org/)
	 * --------------------------------------
	 */
  .latex.source-latex .imp {
    font-weight: bold;
    color: red;
  }
  .latex.source-latex li,
  .latex.source-latex .li1 {
    font-weight: normal;
    vertical-align: top;
  }
  .latex.source-latex .ln {
    width: 1px;
    text-align: right;
    margin: 0;
    padding: 0 2px;
    vertical-align: top;
  }
  .latex.source-latex .li2 {
    font-weight: bold;
    vertical-align: top;
  }
  .latex.source-latex .kw1 {
    color: #800000;
  }
  .latex.source-latex .co1 {
    color: #2c922c;
    font-style: italic;
  }
  .latex.source-latex .es0 {
    color: #000000;
    font-weight: bold;
  }
  .latex.source-latex .sy0 {
    color: #e02020;
  }
  .latex.source-latex .st0 {
    color: #000000;
  }
  .latex.source-latex .re1 {
    color: #8020e0;
    font-weight: normal;
  }
  .latex.source-latex .re2 {
    color: #c08020;
    font-weight: normal;
  }
  .latex.source-latex .re3 {
    color: #8020e0;
    font-weight: normal;
  }
  .latex.source-latex .re4 {
    color: #800000;
    font-weight: normal;
  }
  .latex.source-latex .re5 {
    color: #00008b;
    font-weight: bold;
  }
  .latex.source-latex .re6 {
    color: #800000;
    font-weight: normal;
  }
  .latex.source-latex .re7 {
    color: #0000d0;
    font-weight: normal;
  }
  .latex.source-latex .re8 {
    color: #c00000;
    font-weight: normal;
  }
  .latex.source-latex .re9 {
    color: #2020c0;
    font-weight: normal;
  }
  .latex.source-latex .re10 {
    color: #800000;
    font-weight: normal;
  }
  .latex.source-latex .re11 {
    color: #e00000;
    font-weight: normal;
  }
  .latex.source-latex .re12 {
    color: #800000;
    font-weight: normal;
  }
  .latex.source-latex .ln-xtra,
  .latex.source-latex li.ln-xtra,
  .latex.source-latex div.ln-xtra {
    background-color: #ffc;
  }
  .latex.source-latex span.xtra {
    display: block;
  }

  /*]]>*/

  a.search-result {
    display: block;
    margin-top: @line-height-computed / 2;
    .search-result-content {
      margin-top: @line-height-computed / 4;
      white-space: pre-wrap;
      font-size: 0.8em;
      color: @gray-dark;
      em {
        font-weight: bold;
      }
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      .search-result-content {
        color: @gray-darker;
      }
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    }
  }

  /* Keep the below rules in sync with the wiki pages */
  img {
    height: auto;
    max-width: 100%;
  }

  img.add-vertical-space {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  th.no-wrap {
    white-space: nowrap;
    text-align: left;
  }

  /* LATEX and TEX artwork */
  span.TEX {
    letter-spacing: -0.125em;
    padding-right: 0.5ex;
  }
  span.TEX span.E {
    position: relative;
    top: 0.5ex;
    padding-right: 0.1ex;
  }
  a span.TEX span.E {
    text-decoration: none;
  }
  span.LATEX span.A {
    position: relative;
    top: -0.5ex;
    left: -0.4em;
    font-size: 75%;
  }
  span.LATEX span.TEX {
    position: relative;
    left: -0.4em;
    margin-right: -0.5ex;
  }
}
