/* overbox: white box with shadow borders */
.overbox {
  margin: 0;
  padding: 40px 20px;
  background: @white;
  border: 1px solid @gray-light;

  &.overbox-small {
    padding: 10px;
  }
}
