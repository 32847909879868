.input-suggestions {
  position: relative;
  height: @input-height-base;
}
.input-suggestions-main {
  position: absolute;
  top: 0;
  background-color: transparent;
}

.input-suggestions-shadow {
  background-color: @input-bg;
  padding-top: @input-suggestion-v-offset;
}
.input-suggestions-shadow-existing {
  color: transparent;
}

.input-suggestions-shadow-suggested {
  color: lighten(@input-color, 25%);
}
