// SAAS: @import './list/v1-import-modal.less';

.project-list-page {
  position: absolute;
  top: @header-height;
  bottom: @footer-height;
  padding-bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // Specificity needed to override default `body > .content` values
  body > .content& {
    min-height: calc(~'100vh -' (@header-height - @footer-height));
    padding-top: 0;
  }
}

.project-list-content {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.project-list-empty {
  height: 100%;
  overflow-y: scroll;
}
.project-list-empty-col {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  .row:first-child {
    flex-grow: 1; /* fill vertical space so notifications are pushed to bottom */
  }
  .card {
    // h2 + .card-thin top padding
    padding-bottom: @line-height-computed + @line-height-computed / 2;
  }
}

.sidebar-new-proj-btn {
  .btn-block;
}

.project-list-row {
  height: 100%;
  min-height: calc(~'100vh -' (@header-height - @footer-height));
}
.project-list-container {
  height: 100%;
}
.project-list-sidebar {
  background-color: @sidebar-bg;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-top: @content-margin-vertical;
  padding-bottom: @content-margin-vertical;
  color: @sidebar-color;
  .small {
    color: @sidebar-color;
  }
}

.project-list-main {
  padding-top: @content-margin-vertical;
  padding-bottom: @content-margin-vertical;
  height: 100%;
  margin-left: -(@grid-gutter-width / 2);
  overflow-y: auto;
}

.project-header {
  .btn-group > .btn {
    padding-left: @line-height-base / 2;
    padding-right: @line-height-base / 2;
  }
}

.project-tools {
  display: inline;
  float: right;
  line-height: @line-height-base;
}

.tags-dropdown-menu {
  max-width: 50vw;

  &.dropdown-menu > li > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.project-list-table {
  width: 100%;
  table-layout: fixed;
}
.project-list-table-header-row {
  border-bottom: 1px solid @structured-list-border-color;
}

.project-list-table-row {
  position: relative;
  border-bottom: 1px solid @structured-list-border-color;

  &:last-child {
    border-bottom: 0 none;
  }
  &:hover {
    background-color: @structured-list-hover-color;
  }
  &:first-child {
    border-bottom-color: @structured-header-border-color;
    &:hover {
      background-color: transparent;
    }
  }
}
.project-list-table-name-cell,
.project-list-table-owner-cell,
.project-list-table-lastupdated-cell,
.project-list-table-actions-cell,
.project-list-table-no-projects-cell {
  padding: (@line-height-computed / 4) 0;
  vertical-align: top;
}

.project-list-table-no-projects-cell {
  text-align: center;
}

.project-list-table-name-cell {
  width: 50%;
  padding-right: @line-height-computed / 2;

  @media (min-width: @screen-md) {
    width: 47%;
  }

  @media (min-width: @screen-lg) {
    width: 50%;
  }
}

.project-list-table-name-container {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Extra specificity needed to override Bootstrap's own specificity.
input.project-list-table-select-item[type='checkbox'] {
  position: absolute;
  left: @line-height-computed - (@grid-gutter-width / 2);
  margin-top: 5px;
}

.project-list-table-name {
  display: inline-block;
  padding-left: @line-height-computed * 1.5;
  vertical-align: top;
}

.project-list-table-name-link {
  padding: 0;
}

.project-list-table-owner-cell {
  width: 23%;
  padding-right: @line-height-computed / 2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: @screen-sm) {
    width: 12%;
  }
}

.project-list-table-lastupdated-cell {
  width: 27%;
  padding-right: @line-height-computed / 2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: @screen-sm) {
    width: 14%;
  }
}

.project-list-table-actions-cell {
  display: none;
  padding-right: @line-height-computed - (@grid-gutter-width / 2);
  text-align: right;
  white-space: nowrap;

  @media (min-width: @screen-sm) {
    display: table-cell;
    width: 18%;
  }

  @media (min-width: @screen-md) {
    width: 11%;
  }

  @media (min-width: @screen-lg) {
    width: 10%;
  }
}
.action-btn {
  padding: 0 0.3em;
  margin-left: 0.2em;
}

.first-project {
  width: 127px;
  text-align: center;
}

.user-profile {
  .progress {
    height: @line-height-computed / 2;
    margin-bottom: @line-height-computed / 4;
  }
  p {
    margin-bottom: @line-height-computed / 4;
  }
}

.user-notifications {
  ul {
    margin-bottom: 0px;
  }
  .notification-entry {
    .alert {
      .box-shadow(2px 4px 6px rgba(0, 0, 0, 0.25));
      display: flex;
      flex-wrap: wrap;
      @media (min-width: @screen-sm-min) {
        flex-wrap: nowrap;
      }
    }
  }
}
.notification-body {
  flex-grow: 1;
  width: 90%;
  @media (min-width: @screen-sm-min) {
    width: auto;
  }
}

.notification-action {
  margin-top: (@line-height-computed / 2); // match paragraph padding
  order: 1;
  @media (min-width: @screen-sm-min) {
    margin-top: 0;
    order: 0;
    padding-left: @padding-sm;
  }
}

.notification-close {
  padding-left: @padding-sm;
  text-align: right;
  width: 10%;
  @media (min-width: @screen-sm-min) {
    width: auto;
  }
}

ul.folders-menu {
  margin: @folders-menu-margin;
  .subdued {
    color: @gray-light;
  }
  > li {
    cursor: pointer;
    position: relative;
    > a {
      display: block;
      color: @sidebar-link-color;
      padding: @folders-menu-item-v-padding @folders-menu-item-h-padding;
      border-bottom: solid 1px transparent;
      &:hover {
        background-color: @sidebar-hover-bg;
        text-decoration: @sidebar-hover-text-decoration;
      }
      &:focus {
        text-decoration: none;
      }
    }

    &.separator {
      padding: @folders-menu-item-v-padding @folders-menu-item-h-padding;
      cursor: auto;
    }
  }
  > li.active {
    border-radius: @sidebar-active-border-radius;
    > a {
      background-color: @sidebar-active-bg;
      font-weight: @sidebar-active-font-weight;
      color: @sidebar-active-color;
      .subdued {
        color: @sidebar-active-color;
      }
    }
  }
  > li > a.small {
    color: @gray;
  }
  h2 {
    margin-top: @folders-title-margin-top;
    margin-bottom: @folders-title-margin-bottom;
    font-size: @folders-title-font-size;
    color: @folders-title-color;
    text-transform: @folders-title-text-transform;
    padding: @folders-title-padding;
    font-weight: @folders-title-font-weight;
    font-family: @font-family-sans-serif;
  }
  > li.tag {
    &.active {
      .tag-menu > a {
        color: white;
        border-color: white;
        &:hover {
          background-color: @folders-tag-menu-active-hover;
        }
      }
    }
    &.untagged {
      a.tag-name {
        span.name {
          font-style: italic;
          padding-left: 0;
        }
      }
    }
    &:hover {
      &:not(.active) {
        background-color: @folders-tag-hover;
      }
      .tag-menu {
        display: block;
      }
    }
    &:not(.active) {
      .tag-menu > a:hover {
        background-color: @folders-tag-menu-hover;
      }
    }
    .tag-name {
      position: relative;
      padding: @folders-tag-padding;
      display: @folders-tag-display;
      span.name {
        padding-left: 0.5em;
        line-height: @folders-tag-line-height;
      }
    }
    .tag-menu {
      > a {
        border: 1px solid @folders-tag-border-color;
        border-radius: @border-radius-small;
        color: @folders-tag-menu-color;
        display: block;
        width: 16px;
        height: 16px;
        position: relative;
        .caret {
          position: absolute;
          top: 6px;
          left: 1px;
        }
      }
      display: none;
      position: absolute;
      top: 50%;
      margin-top: -8px; // Half the element height.
      right: 4px;
      &.open {
        display: block;
      }
    }
    .dropdown-toggle {
      float: right;
    }
  }
}

.project-action-alert {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
.project-action-alert-msg {
  flex-grow: 1;
  padding-right: 10px;
}

form.project-search {
  .form-group {
    margin-bottom: 0;
  }
}

.project-search-clear-btn {
  width: 100%;
  padding: 0;
  border: 0;
  color: @ol-type-color;

  &:hover,
  &:active {
    color: @ol-type-color;
  }
}

ul.structured-list {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  li {
    border-bottom: 1px solid @structured-list-border-color;
    padding: (@line-height-computed / 4) 0;

    &:last-child {
      border-bottom: 0 none;
    }
    &:hover {
      background-color: @structured-list-hover-color;
    }
    &:first-child {
      border-bottom-color: @structured-header-border-color;
      &:hover {
        background-color: transparent;
      }
    }
    a {
      color: @structured-list-link-color;
    }
    .header {
      font-weight: 600;
    }
    .select-item,
    .select-all {
      position: absolute;
      left: @line-height-computed;
    }
    .select-item + span,
    .select-all + span {
      display: inline-block;
      padding-left: @line-height-computed * 1.5;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
    }
  }
}

.project-list-card {
  padding: 0 (@line-height-computed / 4);
}

ul.project-list {
  li {
    .projectName {
      margin-right: @line-height-computed / 4;
    }
  }
}

i.tablesort {
  padding-left: 8px;
}

.tag-label {
  margin-left: @line-height-computed / 4;
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  top: @tag-top-adjustment;
}
// Extra specificity needed to override Bootstrap's own specificity.
.label.tag-label-name,
.label.tag-label-remove {
  display: inline-block;
  padding: 3px 4px;
  border-radius: @tag-border-radius;
  background-color: @tag-bg-color;
  color: @tag-color;
  vertical-align: text-bottom;
  border-width: 0;
  &:hover,
  &:focus {
    color: @tag-color;
    background-color: @tag-bg-hover-color;
    outline-width: 0;
  }
}
.label.tag-label-name {
  padding-right: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-width: @tag-max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  > .fa {
    margin-right: 0.3em;
  }
}
.label.tag-label-remove {
  padding-left: 2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.user_details_auto_complete {
  ul > li {
    list-style: none;
  }

  .autocomplete {
    width: 100%;
    position: relative;
  }

  .autocomplete ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: @zindex-dropdown;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0; // override default ul
    list-style: none;
    font-size: @font-size-base;
    background-color: @dropdown-bg;
    border: 1px solid @dropdown-fallback-border; // IE8 fallback
    border: 1px solid @dropdown-border;
    border-radius: @border-radius-base;
    .box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
    background-clip: padding-box;

    // Links within the dropdown menu
    > li {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: @line-height-base;
      color: @dropdown-link-color;
      white-space: nowrap; // prevent links from randomly breaking onto new lines
    }

    > li.active {
      text-decoration: none;
      color: @dropdown-link-hover-color;
      background-color: @dropdown-link-hover-bg;
    }
  }
  .autocomplete .highlight {
    font-weight: 700;
  }
}

.minimal-create-proj-dropdown {
  text-align: center;

  &-menu {
    width: 200px;
    left: 50%;
    margin-left: -100px;
  }
}
