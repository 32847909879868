.page-style {
  background-color: @white;

  a:not(.btn) {
    text-decoration: underline;
  }

  .page-header {
    border-bottom: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .card {
    background-color: @blue-10;
    border-radius: 24px;
  }
}
