.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice {
  width: auto;
  max-width: 400px;
}

.dropdown-menu .ui-select-choices-row {
  padding: 4px 0;

  > .ui-select-choices-row-inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ui-select-placeholder,
.ui-select-match-text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.ui-select-bootstrap {
  &:focus {
    outline: none;
  }
  > .ui-select-match {
    &:focus {
      outline: none;
    }
    &.btn-default-focus {
      outline: 0;
      box-shadow: none;
      background-color: transparent;
      > .btn {
        border-color: @input-border-focus;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px fade(@input-border-focus, 60%);
        padding-top: @input-suggestion-v-offset;
      }
    }
    > .btn {
      color: @input-color;
      background-color: @input-bg;
      border: 1px solid @input-border;
      padding-top: @input-suggestion-v-offset;
      &[disabled] {
        cursor: not-allowed;
        background-color: @input-bg-disabled;
        opacity: 1;
      }
    }
  }
}

.ui-select-container[tagging] {
  .ui-select-toggle {
    cursor: text;
    padding-top: @input-suggestion-v-offset;
    > i.caret.pull-right {
      display: none;
    }
  }
}
