@import (less) '../fonts/font-awesome.css';

@import 'core/mixins.less';

// Reset
@import 'core/normalize.less';
@import 'core/print.less';

// Vendor CSS
// SAAS: @import (less) 'vendor/pdfListView/TextLayer.css';
// SAAS: @import (less) 'vendor/pdfListView/AnnotationsLayer.css';
// SAAS: @import (less) 'vendor/pdfListView/HighlightsLayer.css';
@import (less) 'vendor/mathjax/all.less';
// SAAS: @import (less) 'vendor/select/select.css';
// SAAS: @import (less) 'vendor/codemirror.css';
// SAAS: @import (less) 'vendor/codemirror-dialog.css';
// SAAS: @import (less) 'vendor/codemirror-show-hint.css';

// Core CSS
@import 'core/scaffolding.less';
@import 'core/type.less';
@import 'core/grid.less';
@import 'core/accessibility.less';
@import 'core/spacing.less';

// Components
@import 'components/split-pane';
@import 'components/tables.less';
@import 'components/forms.less';
@import 'components/buttons.less';
@import 'components/card.less';
//@import "components/code.less";
@import 'components/component-animations.less';
@import 'components/dropdowns.less';
@import 'components/button-groups.less';
@import 'components/input-groups.less';
@import 'components/navs.less';
@import 'components/navbar.less';
@import 'components/footer.less';
//@import "components/breadcrumbs.less";
//@import "components/pagination.less";
@import 'components/notifications.less';
@import 'components/pager.less';
@import 'components/labels.less';
//@import "components/badges.less";
//@import "components/jumbotron.less";
@import 'components/thumbnails.less';
@import 'components/alerts.less';
@import 'components/progress-bars.less';
// @import "components/media.less";
// @import "components/list-group.less";
// @import "components/panels.less";
// @import "components/wells.less";
@import 'components/close.less';
@import 'components/fineupload.less';
@import 'components/hover.less';
@import 'components/ui-select.less';
@import 'components/input-suggestions.less';
// SAAS: @import 'components/nvd3.less';
// SAAS: @import 'components/nvd3_override.less';
@import 'components/infinite-scroll.less';
@import 'components/expand-collapse.less';
@import 'components/beta-badges.less';
@import 'components/divider.less';

// Components w/ JavaScript
@import 'components/modals.less';
@import 'components/tooltip.less';
@import 'components/popovers.less';
@import 'components/carousel.less';
// SAAS: @import 'components/daterange-picker';
@import 'components/vertical-resizable-panes.less';

// ngTagsInput
@import 'components/tags-input.less';

// Utility classes
@import 'core/utilities.less';
@import 'core/responsive-utilities.less';

// ShareLaTeX app classes
@import 'app/base.less';
@import 'app/account-settings.less';
@import 'app/beta-program.less';
// SAAS: @import 'app/about-page.less';
@import 'app/project-list.less';
@import 'app/project-token-access.less';
@import 'app/project-list-react.less';
@import 'app/editor.less';
// SAAS: @import 'app/homepage.less';
// SAAS: @import 'app/plans.less';
// SAAS: @import 'app/plans-v2.less';
// SAAS: @import 'app/recurly.less';
// SAAS: @import 'app/bonus.less';
@import 'app/register.less';
// SAAS: @import 'app/blog.less';
// SAAS: @import 'app/features.less';
// SAAS: @import 'app/templates.less';
@import 'app/wiki.less';
// SAAS: @import 'app/translations.less';
// SAAS: @import 'app/contact-us.less';
// SAAS: @import 'app/subscription.less';
@import 'app/sprites.less';
@import 'app/invite.less';
@import 'app/error-pages.less';
// SAAS: @import 'app/editor/history-v2.less';
// SAAS: @import 'app/metrics.less';
@import 'app/open-in-overleaf.less';
// SAAS: @import 'app/primary-email-check';
// SAAS: @import 'app/project-url-lookup';
// SAAS: @import 'app/grammarly';

// module styles
// TODO: find a way for modules to add styles dynamically
// SAAS: @import 'modules/symbol-palette.less';
// SAAS: @import 'modules/galileo.less';

// SAAS: @import 'modules/admin-panel.less';
