:root {
  --ol-blue: @ol-blue;

  --ol-blue-gray-0: @ol-blue-gray-0;
  --ol-blue-gray-1: @ol-blue-gray-1;
  --ol-blue-gray-2: @ol-blue-gray-2;
  --ol-blue-gray-3: @ol-blue-gray-3;
  --ol-blue-gray-4: @ol-blue-gray-4;
  --ol-blue-gray-5: @ol-blue-gray-5;
  --ol-blue-gray-6: @ol-blue-gray-6;

  --input-color: @input-color;
  --input-border: @input-border;
  --input-border-radius: @input-border-radius;
  --input-border-focus: @input-border-focus;

  --btn-border-radius-base: @btn-border-radius-base;
  --btn-default-bg: @btn-default-bg;

  --line-height-base: @line-height-base;
}
