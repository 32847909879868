//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
  visibility: hidden !important;
}

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}

.w-100 {
  width: 100%;
}

// Overflow utils
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
