.MJX_Assistive_MathML {
  position: absolute !important;
  top: 0;
  left: 0;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 1px 0 0 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.MJX_Assistive_MathML.MJX_Assistive_MathML_Block {
  width: 100% !important;
}
