.outline-container {
  width: 100%;
  background-color: @file-tree-bg;
}

.outline-pane {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: @font-size-small;
  color: @file-tree-item-color;
}

.outline-pane-disabled {
  opacity: 0.5;
}

.outline-header {
  .toolbar-small-mixin;
  .toolbar-alt-mixin;
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid @toolbar-border-color;
  border-top: 1px solid @toolbar-border-color;
}

.outline-header-expand-collapse-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0 (@outline-h-rhythm * 0.25) 0 0;
  font-size: inherit;
  vertical-align: inherit;
  color: @file-tree-item-color;
  flex: 1 0 100%;
  text-align: left;
  white-space: nowrap;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  &:hover,
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: @outline-header-hover-bg;
  }
  &:hover[disabled] {
    background-color: transparent;
  }
}

.outline-header-name {
  display: inline-block;
  font-family: @font-family-sans-serif;
  font-size: @font-size-small;
  color: @file-tree-item-color;
  font-weight: 700;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outline-body {
  overflow-y: auto;
  background-color: @file-tree-bg;
  padding-right: @outline-h-rhythm * 0.25;
}

.outline-body-no-elements {
  color: @outline-no-items-color;
  text-align: center;
  padding: @outline-v-rhythm @outline-h-rhythm (@outline-v-rhythm * 2);
  margin-right: -(@outline-h-rhythm * 0.25);
}

.outline-body-link {
  display: block;
  color: @file-tree-item-color;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: @file-tree-item-color;
    text-decoration: underline;
  }
}

.outline-item-list {
  position: relative;
  list-style: none;
  padding-left: @outline-h-rhythm;

  &::before {
    content: '';
    background-color: @outline-line-guide-color;
    top: @outline-h-rhythm / 4;
    bottom: @outline-h-rhythm / 4;
    width: 1px;
    left: (@outline-h-rhythm * 1.5);
    position: absolute;
  }

  &.outline-item-list-root {
    padding-left: 0;
    &::before {
      left: (@outline-h-rhythm * 0.5);
    }
  }
}

.outline-item-no-children {
  padding-left: @outline-h-rhythm - @outline-item-h-padding;
}

.outline-item-row {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.outline-item-expand-collapse-btn {
  display: inline;
  border: 0;
  padding: 0;
  font-size: inherit;
  vertical-align: inherit;
  position: relative;
  z-index: 1;
  background-color: @file-tree-bg;
  color: @outline-expand-collapse-color;
  margin-right: -(@outline-item-h-padding);
  border-radius: @border-radius-base;
  &:hover,
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: @file-tree-item-hover-bg;
  }
}

.outline-item-link {
  display: inline;
  color: @file-tree-item-color;
  background-color: transparent;
  border: 0;
  position: relative;
  z-index: 1;
  padding: 0 @outline-item-h-padding;
  line-height: @outline-v-rhythm;
  border-radius: @border-radius-base;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  &:hover,
  &:focus {
    outline: 0;
    background-color: @file-tree-item-hover-bg;
  }
}

.outline-item-link-highlight {
  background-color: @outline-highlight-bg;
}

.outline-caret-icon {
  width: @outline-h-rhythm;
  font-size: 17px;
  text-align: center;
}
