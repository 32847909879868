.vertical-resizable-resizer {
  background-color: @vertical-resizable-resizer-bg;

  &:hover {
    background-color: @vertical-resizable-resizer-hover-bg;
  }

  &::after {
    content: '\00b7\00b7\00b7\00b7';
    display: block;
    color: @ol-blue-gray-2;
    text-align: center;
    font-size: 20px;
    line-height: 3px;
    pointer-events: none;
  }
}

.vertical-resizable-resizer-disabled {
  pointer-events: none;
  opacity: 0.5;
  &::after {
    opacity: 0.5;
  }
}
