.circle-img {
  border-radius: 50%;
  float: left;
  // float: right;
  height: 100px;
  overflow: hidden;
  position: relative;
  max-width: 100px;
  img {
    display: inline;
    margin: 0 auto;
    width: 100%;
  }
}
.blockquote-with-img {
  margin-left: 115px;
}
