.ol-tabs {
  // Overrides for nav.less
  .nav-tabs {
    border: 0 !important;
    margin-bottom: 0;
    margin-top: -@line-height-computed; //- adjusted for portal-name
    padding: @padding-lg 0 @padding-md;
    text-align: center;
  }

  .nav-tabs > li {
    display: inline-block;
    float: none;
    a {
      border: 0;
      color: @link-color-alt;
      &:focus,
      &:hover {
        background-color: transparent !important;
        border: 0;
        color: @link-hover-color-alt;
      }
    }
  }

  li.active > a {
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid @accent-color-secondary!important;
    color: @accent-color-secondary!important;
    &:hover {
      border-bottom: 1px solid @accent-color-secondary!important;
      color: @accent-color-secondary!important;
    }
  }
  .tab-content:extend(.container) {
    background-color: transparent !important;
    border: none !important;
  }
}

// Scroll the page up a bit to allow tab links to be shown when navigating to
// a bookmarked tab hash
[data-ol-bookmarkable-tabset] .tab-pane {
  scroll-margin-top: calc(
    @line-height-computed + // Computed text height
    20px + // nav-link-padding top & bottom
    @padding-md + // ol-tabs bottom padding
    (@line-height-computed / 2) // tab-content top padding
  );
}
