// Not part of BS3 but adding those with the intention to move to BS5
// https://digital-science.slack.com/archives/C014X5MJB8S/p1650008783200769
@spacers: {
  0: 0;
  1: @margin-xs;
  2: @margin-sm;
  3: @margin-md;
  4: @margin-lg;
  5: @margin-xl;
  6: @margin-xxl;
};

each(@spacers, {
  // Margins
  .m-@{key} {
    margin: @value !important;
  }
  .ms-@{key} {
    margin-left: @value !important;
  }
  .me-@{key} {
    margin-right: @value !important;
  }
  .mt-@{key} {
    margin-top: @value !important;
  }
  .mb-@{key} {
    margin-bottom: @value !important;
  }
  .mx-@{key} {
    margin-left: @value !important;
    margin-right: @value !important;
  }
  .my-@{key} {
    margin-top: @value !important;
    margin-bottom: @value !important;
  }

  // Negative margins
  .m-n@{key} {
    margin: -@value !important;
  }
  .ms-n@{key} {
    margin-left: -@value !important;
  }
  .me-n@{key} {
    margin-right: -@value !important;
  }
  .mt-n@{key} {
    margin-top: -@value !important;
  }
  .mb-n@{key} {
    margin-bottom: -@value !important;
  }
  .mx-n@{key} {
    margin-left: -@value !important;
    margin-right: -@value !important;
  }
  .my-n@{key} {
    margin-top: -@value !important;
    margin-bottom: -@value !important;
  }

  // Paddings
  .p-@{key} {
    padding: @value !important;
  }
  .ps-@{key} {
    padding-left: @value !important;
  }
  .pe-@{key} {
    padding-right: @value !important;
  }
  .pt-@{key} {
    padding-top: @value !important;
  }
  .pb-@{key} {
    padding-bottom: @value !important;
  }
  .px-@{key} {
    padding-left: @value !important;
    padding-right: @value !important;
  }
  .py-@{key} {
    padding-top: @value !important;
    padding-bottom: @value !important;
  }
});

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.ms-auto {
  margin-left: auto !important;
}

.me-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}
