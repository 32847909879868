.project-invite-accept {
  form {
    padding-top: 15px;
  }
  margin-bottom: 30px;
}

.project-name-tooltip .tooltip-inner {
  max-width: 80vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-invite-invalid {
  .actions {
    padding-top: 15px;
  }
  margin-bottom: 30px;
}
