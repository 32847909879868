.MathJax_Preview {
  color: #888;
  display: contents;
}
#MathJax_Message {
  position: fixed;
  left: 1em;
  bottom: 1.5em;
  background-color: #e6e6e6;
  border: 1px solid #959595;
  margin: 0px;
  padding: 2px 8px;
  z-index: 102;
  color: black;
  font-size: 80%;
  width: auto;
  white-space: nowrap;
}
#MathJax_MSIE_Frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  z-index: 101;
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.MathJax_Error {
  color: #cc0000;
  font-style: italic;
}
